import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  darkMode: boolean = false;

  constructor(private plt: Platform) {
    // this.plt.ready().then(() => {
    //   const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    //   prefersDark.addListener((e) => {
    //     this.setAppTheme(e.matches);
    //   });
    // });

    try {
      if (window.localStorage.getItem("theme")) {
        window.localStorage.getItem("theme") === "dark"
          ? this.setAppTheme(true)
          : this.setAppTheme(false);
      } else {
        window.localStorage.setItem("theme", "light");
        this.setAppTheme(this.darkMode);
      }
    } catch {
      this.setAppTheme(this.darkMode);
    }
  }

  toggleAppTheme() {
    this.darkMode = !this.darkMode;
    this.setAppTheme(this.darkMode);
  }

  setAppTheme(dark) {
    this.darkMode = dark;
    if (this.darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }
}
