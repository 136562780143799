import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";

import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { Observable, of, merge } from "rxjs";
import { switchMap, first, timestamp } from "rxjs/operators";
import { User } from "./user.model";

import { AudioService } from "../service/audio.service";
import { stringify } from "querystring";
import { Image } from "../dashboard/interfaces/image";
import { Video } from "../dashboard/interfaces/video";
import { Track } from "../dashboard/interfaces/track";
import { IonicModule, Platform } from "@ionic/angular";
import { ThemeService } from "./theme.service";
import * as firebase from "firebase/app";
import { AngularFireStorage } from "@angular/fire/storage";
import { successData } from "../payment/payment.model";
import { HttpClient } from "@angular/common/http";
import { createSSRSearchClient } from "angular-instantsearch";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user$: Observable<User>;
  competitionPeriod: string;

  webClientID: string =
    "22811957734-ikrb0lc3f036r04k2hnqd7p61rhc631e.apps.googleusercontent.com";

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private aud: AudioService,
    //private firebaseAuthentication: FirebaseAuthentication,
    public platform: Platform, //private googlePlus: GooglePlus
    public themeService: ThemeService,
    public storage: AngularFireStorage,
    private http: HttpClient
  ) {
    firebase.analytics();
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<User>("users/" + user.uid).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  async emailPassLogin(email: string, password: string) {
    const login = await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here
        var errorMessage = error.message;
        return { error: errorMessage };
      });

    return login;
  }

  async emailPassSignup(email: string, pass: string, displayName: string) {
    const signup = await this.afAuth
      .createUserWithEmailAndPassword(email, pass)
      .catch(function (error) {
        // Handle Errors here.
        var errorMessage = error.message;
        return { error: errorMessage };
        // ...
      });

    const uid = (await this.afAuth.currentUser).uid;

    if (uid != null) {
      this.updateUserDataEP(
        (await this.afAuth.currentUser).uid,
        email,
        displayName,
        "",
        "",
        "",
        true
      );
    }

    return signup;
  }

  async forgotPassword(email: string) {
    const result = await this.afAuth
      .sendPasswordResetEmail(email)
      .catch((error) => {
        var errorMessage: string = error.message;
        return errorMessage;
      });

    return result;
  }

  async googleSignup() {
    const provider = new auth.GoogleAuthProvider();
    await this.afAuth.signInWithPopup(provider).then((credential) => {
      this.updateUserDataGoogle(credential.user as any, "", "", true);
    });
  }

  async googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    await this.afAuth.signInWithPopup(provider);
  }

  async updateBio(bio: string, CB: string) {
    let id = await this.getUID();
    await this.afs.collection("users").doc(id).update({
      biography: bio,
      creativeBackground: CB,
    });
  }

  private updateUserDataEP(
    uid: string,
    email: string,
    displayName: string,
    photoURL: string,
    biography,
    creativeBackground,
    newUser
  ) {
    //Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      "users/" + uid
    );

    const data: User = {
      uid,
      email,
      displayName,
      photoURL,
      biography,
      creativeBackground,
      twitter: "",
      instagram: "",
      facebook: "",
      linkedin: "",
      location: "",
      industry: "",
      profilePicture: "../../../assets/profileicon.png",
      gender: "",
      darkMode: true,
    };

    return userRef.set(data, { merge: true });
  }

  private updateUserDataGoogle(
    { uid, email, displayName, photoURL }: User,
    biography,
    creativeBackground,
    newUser
  ) {
    //Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      "users/" + uid
    );

    const data: User = {
      uid,
      email,
      displayName,
      photoURL,
      biography,
      creativeBackground,
      twitter: "",
      instagram: "",
      facebook: "",
      linkedin: "",
      location: "",
      industry: "",
      profilePicture: "../../../assets/profileicon.png",
      darkMode: true,
      gender: "",
    };

    return userRef.set(data, { merge: true });
  }

  toggleDarkMode(uid: string, dark: boolean) {
    this.afs.doc("users/" + uid).update({ darkMode: dark });
  }

  getUserInfo(uid: string) {
    const userRef = this.afs.doc("users/" + uid);
    return userRef.get();
  }

  async getResizedDownloadUrl(
    retries: number,
    filePath: string,
    writePath: string,
    profilePicture: boolean
  ) {
    setTimeout(async () => {
      const downloadUrl = await this.storage
        .ref(filePath)
        .getDownloadURL()
        .toPromise()
        .then((url) => {
          return url;
        })
        .catch(() => {
          return null;
        });
      if (downloadUrl != null) {
        if (!profilePicture) {
          this.writeResizedUrl(downloadUrl, writePath);
        } else {
          this.writeResizedProfilePicture(downloadUrl, writePath);
        }
      } else {
        if (retries > 0) {
          this.getResizedDownloadUrl(
            retries - 1,
            filePath,
            writePath,
            profilePicture
          );
        }
      }
    }, 2000);
  }

  addSubscriptionData(paymentData: successData) {
    const id = this.afs.createId();
    const path = "payments/subscriptions/" + paymentData.uid + "/" + id;
    return this.afs.doc(path).set(paymentData);
  }

  addFeaturedPaymentData(paymentData: successData) {
    const path =
      "payments/featured/" + paymentData.uid + "/" + paymentData.item;
    return this.afs.doc(path).set(paymentData);
  }

  checkIfSubscribed(uid: string) {
    const subRef = this.afs.collection("payments/subscriptions/" + uid, (ref) =>
      ref.where("status", "==", "SUCCESS").orderBy("timestamp", "desc").limit(1)
    );
    return subRef.get();
  }

  checkIfPaidFeature(competitionId: string, uid: string) {
    return this.afs.doc("payments/featured/" + uid + "/" + competitionId).get();
  }

  async writeResizedProfilePicture(url: string, path: string) {
    return await this.afs
      .doc(path)
      .update({ profilePicture: url })
      .then((success) => {
        return success;
      })
      .catch((error) => {
        return error;
      });
  }

  async writeResizedUrl(url: string, path: string) {
    return await this.afs
      .doc(path)
      .update({ resizedUrl: url })
      .then((success) => {
        return success;
      })
      .catch((error) => {
        return error;
      });
  }

  updateStreamId(streamid: string, uid: string) {
    this.afs.doc("users/" + uid).update({
      streamId: streamid,
    });
  }

  updateBroadCastId(broadcastid: string, uid: string) {
    this.afs.doc("users/" + uid).update({
      broadcastId: broadcastid,
    });
  }

  updateEmbed(embed: string, uid: string) {
    this.afs.doc("users/" + uid).update({
      embedStream: embed,
    });
  }

  logOut() {
    this.afAuth.signOut();
  }

  async checkUserLoggedIn() {
    const u = await this.afAuth.authState.pipe(first()).toPromise();
    if (u) {
      return true;
    } else {
      return false;
    }
  }

  async getUID() {
    let s: string = (await this.afAuth.currentUser).uid;
    return s;
  }

  addUploadData(
    title: string,
    description: string,
    uid: string,
    url: string,
    type: string,
    unixTimestamp: number,
    category: string,
    tags: string,
    displayName: string,
    profilePicture: string
  ) {
    const uploadData = {
      title: title,
      likes: 0,
      views: 0,
      comments: 0,
      path: "uploads/" + uid + "/uploaded/" + title,
      url: url,
      description: description,
      type: type,
      timestamp: unixTimestamp,
      category: category,
      tags: tags,
      uid: uid,
      displayName: displayName,
      profilePicture: profilePicture,
    };

    return this.afs
      .doc("uploads/" + uid + "/uploaded/" + title)
      .set(uploadData);
  }

  getUploadInfoList(uid: string) {
    var urlList: Array<any> = [];
    const uploadRef = this.afs.collection(
      "uploads/" + uid + "/uploaded",
      (ref) => ref.orderBy("timestamp", "desc")
    );
    uploadRef.get().subscribe((x) => {
      x.forEach(function (doc) {
        urlList.push(doc.data());
      });
    });
    return urlList;
  }

  getUserUploads(uid: string) {
    const uploadRef = this.afs.collection(
      "uploads/" + uid + "/uploaded",
      (ref) => ref.orderBy("timestamp", "desc")
    );

    return uploadRef.get();
  }

  //delete this comments function when override  with dashboard style
  getCommentList(path: string) {
    var commentList: Array<any> = [];
    const commentRef = this.afs.collection(path + "/comments");
    commentRef.get().subscribe((x) => {
      x.forEach(function (doc) {
        commentList.push(doc.data());
      });
    });
    return commentList;
  }

  getComments(path: string) {
    const commentRef = this.afs.collection(path + "/comments");
    return commentRef.get();
  }

  getcompetitionConfig() {
    return this.afs.doc("competitions/CONFIG").get();
  }

  getPeriodDetails(competitionPeriod: string) {
    return this.afs.doc("competitions/" + competitionPeriod).get();
  }

  getCompetitionsList(competitionPeriod: string) {
    const compRef = this.afs.collection(
      "competitions/" + competitionPeriod + "/competitions",
      (ref) => ref.where("enabled", "==", true)
    );
    return compRef.get();
  }

  getSpecificCompetition(id: string, competitionPeriod: string) {
    return this.afs
      .collection("competitions/" + competitionPeriod + "/competitions/")
      .doc(id)
      .get();
  }

  getUsersCompetitionInfo(uid: string, competitionPeriod: string) {
    return this.afs
      .doc("competitions/" + competitionPeriod + "/users/" + uid)
      .get();
  }

  enterCompetition(
    competitionUid: string,
    entryTitle: string,
    userUid: string,
    uploadUrl: string,
    displayName: string,
    profilePicture: string,
    competitionPeriod: string,
    entryType: string,
    category: string
  ) {
    const timestamp = (new Date().getTime() / 1000) | 0;
    const entryId = this.afs.createId();

    const entryData = {
      title: entryTitle,
      uid: userUid,
      url: uploadUrl,
      displayName: displayName,
      profilePicture: profilePicture,
      competitionId: competitionUid,
      type: entryType,
      timestamp: timestamp,
      lastInteracted: timestamp,
      entryId: entryId,
      votes: 0,
      category: category,
    };

    const entryRef = this.afs
      .collection(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionUid +
          "/entries"
      )
      .doc(entryId);

    entryRef.set(entryData);

    const uploadedId = this.afs.createId();

    this.afs
      .collection("uploads/" + userUid + "/uploaded/")
      .doc(uploadedId)
      .set(entryData)
      .then(() => {
        if (entryType.includes("image")) {
          const compPath =
            "competitions/" +
            competitionUid +
            "/entries/" +
            userUid +
            "/" +
            entryTitle +
            "_500x500";
          const writePath =
            "competitions/" +
            competitionPeriod +
            "/competitions/" +
            competitionUid +
            "/entries/" +
            entryId;
          const writeUploadPath =
            "uploads/" + userUid + "/uploaded/" + uploadedId;
          this.getResizedDownloadUrl(10, compPath, writePath, false);
          this.getResizedDownloadUrl(10, compPath, writeUploadPath, false);
        }
      });
  }

  checkIfEntered(
    competitionPeriod: string,
    competitionId: string,
    uid: string
  ) {
    return this.afs
      .collection(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionId +
          "/entries/",
        (ref) => ref.where("uid", "==", uid)
      )
      .get();
  }

  getUserCompetitions(uid: string) {
    const compRef = this.afs.collectionGroup("entries", (ref) =>
      ref.where("uid", "==", uid)
    );
    return compRef.get();
  }

  getTotalCompetitionPoints() {
    const compRef = this.afs.collection("competitions", (ref) =>
      ref.where("internal", "==", true)
    );
    return compRef.get();
  }

  viewCompetitionEntries(competitionUid: string, competitionPeriod: string) {
    return this.afs
      .collection(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionUid +
          "/entries",
        (ref) => ref.orderBy("lastInteracted", "desc").limit(30)
      )
      .get();
  }

  viewMoreCompetitionEntries(
    competitionUid: string,
    competitionPeriod: string,
    lastItem
  ) {
    return this.afs
      .collection(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionUid +
          "/entries",
        (ref) =>
          ref.orderBy("lastInteracted", "desc").startAfter(lastItem).limit(30)
      )
      .get();
  }

  getCompetitionTop20(competitionUid: string, competitionPeriod: string) {
    return this.afs
      .collection("competitions/" + competitionPeriod + "/users", (ref) =>
        ref.orderBy(competitionUid + ".votes", "desc").limit(20)
      )
      .get();
  }

  competitionSingleEntryVote(
    competitionUid: string,
    competitionPeriod: string,
    entryId: string
  ) {
    return this.afs
      .doc(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionUid +
          "/entries/" +
          entryId
      )
      .get();
  }

  submitVote(
    competitionUid: string,
    entryId: string,
    yourUid: string,
    contestantUid: string,
    competitionPeriod: string
  ) {
    const votePath =
      "competitions/" +
      competitionPeriod +
      "/competitions/" +
      competitionUid +
      "/entries/" +
      entryId +
      "/votes";

    return this.afs.collection(votePath).doc(yourUid);
  }

  getTotalVotes(competitionId: string) {
    return this.afs.collectionGroup("votes-" + competitionId).valueChanges();
  }

  getEntryVotes(
    competitionId: string,
    entryId: string,
    competitionPeriod: string
  ) {
    return this.afs
      .collection(
        "competitions/" +
          competitionPeriod +
          "/competitions/" +
          competitionId +
          "/entries/" +
          entryId +
          "/votes"
      )
      .valueChanges();
  }

  getHomeContent() {
    return this.afs.collection("home-config").doc("TopUsers").get();
  }

  likeUpload(
    youruid: string,
    likes: number,
    title: string,
    theiruid: string,
    yourdisplayName: string,
    type: string
  ) {
    const likeRef = this.afs
      .collection("uploads/" + theiruid + "/uploaded/" + title + "/likes")
      .doc(youruid);

    likeRef.get().subscribe((like) => {
      if (!like.exists) {
        const likeData = {
          likedBy: youruid,
        };
        likeRef.set(likeData);

        this.afs
          .doc("uploads/" + theiruid + "/uploaded/" + title)
          .update({ likes: likes + 1 });
      }
    });

    // var timestamp = (new Date().getTime() / 1000) | 0;
    // var notification = yourdisplayName + " liked your upload (" + title + ")";
    // if (type == "post") {
    //   notification = yourdisplayName + " liked your post";
    // }
    // const noti = this.afs.doc(
    //   "notifications/" + theiruid + "/notification/" + timestamp
    // );

    // const notiData = {
    //   notification: notification,
    //   timestamp: timestamp,
    //   seen: false,
    // };

    // noti.set(notiData);
  }

  hasLiked(youruid: string, theiruid: string, title: string) {
    return this.afs
      .collection("uploads/" + theiruid + "/uploaded/" + title + "/likes")
      .doc(youruid)
      .get();
  }

  checkRefExists(path: string) {
    const checkRef = this.afs.doc(path);
    return checkRef.get();
  }

  updateProfile(path: string, data) {
    this.afs.doc(path).update(data);
  }

  addComment(
    path: string,
    uid: string,
    comment: string,
    date: string,
    displayName: string,
    profilePicture: string,
    theiruid: string,
    title: string,
    type: string
  ) {
    var timestamp = (new Date().getTime() / 1000) | 0;
    var notification =
      displayName + " commented on your upload (" + title + ")";
    if (type == "post") {
      notification = displayName + " commented on your post";
    }
    const noti = this.afs.doc(
      "notifications/" + theiruid + "/notification/" + timestamp
    );

    const notiData = {
      notification: notification,
      timestamp: timestamp,
      seen: false,
    };

    noti.set(notiData);

    const uploadRef = this.afs.doc(
      "uploads/" + theiruid + "/uploaded/" + title
    );

    uploadRef.get().subscribe((upload) => {
      const currentComments = upload.data().comments;

      uploadRef.update({ comments: currentComments + 1 });
    });

    const commentRef = this.afs.doc(path + "/comments/" + uid + date);

    const commentData = {
      comment: comment,
      displayName: displayName,
      date: date,
      profilePicture: profilePicture,
    };

    return commentRef.set(commentData);
  }

  async setProfilePicture(uid: string, url: string) {
    const userRef = this.afs.doc("users/" + uid);
    await userRef.update({ profilePicture: url });
  }

  follow(
    theiruid: string,
    youruid: string,
    theirdisplayName: string,
    yourdisplayName: string
  ) {
    var timestamp = (new Date().getTime() / 1000) | 0;
    var notification = yourdisplayName + " followed you";
    const noti = this.afs.doc(
      "notifications/" + theiruid + "/notification/" + timestamp
    );

    const notiData = {
      notification: notification,
      timestamp: timestamp,
      seen: false,
    };

    noti.set(notiData);

    const followRef = this.afs.doc(
      "following/" + youruid + "/followed/" + theiruid
    );
    followRef.set(
      { uid: theiruid, displayName: theirdisplayName },
      { merge: true }
    );

    const followersRef = this.afs.doc(
      "followers/" + theiruid + "/follow/" + youruid
    );
    return followersRef.set(
      { uid: youruid, displayName: yourdisplayName },
      { merge: true }
    );
  }

  getFollowers(uid: string) {
    const followersRef = this.afs.collection("followers/" + uid + "/follow");
    return followersRef.get();
  }

  getFollowing(uid: string) {
    const followingRef = this.afs.collection("following/" + uid + "/followed");
    return followingRef.get();
  }

  getTotalLikes(uid: string) {
    const likeRef = this.afs.collectionGroup(uid);
    return likeRef.get();
  }

  addToFavourites(uid: string, path: string, theiruid: string, title: string) {
    const favouritesRef = this.afs.doc(
      "favourites/" + uid + "/favourite/" + theiruid + title
    );
    return favouritesRef.set({ path: path }, { merge: true });
  }

  getFavourites(uid: string) {
    var images: Image[] = [];
    var videos: Video[] = [];
    var tracks: Track[] = [];
    var favouriteList = {
      images: images,
      videos: videos,
      tracks: tracks,
    };

    const favRef = this.afs.collection("favourites/" + uid + "/favourite");
    favRef.get().subscribe((x) => {
      x.forEach((doc) => {
        this.afs
          .doc(doc.data().path)
          .get()
          .subscribe((up) => {
            if (up.data().type.includes("audio")) {
              this.getComments(
                "uploads/" + up.data().uid + "/uploaded/" + up.data().title
              ).subscribe((snapshot) => {
                const Track = {
                  title: up.data().title,
                  displayName: up.data().displayName,
                  profilePicture: up.data().profilePicture,
                  uid: up.data().uid,
                  url: up.data().url,
                };
                this.aud.addToPlaylist(Track);
                tracks.push({
                  data: Track,
                  likes: up.data().likes,
                  comments: up.data().comments,
                });
              });
            } else if (up.data().type.includes("image")) {
              this.getComments(
                "uploads/" + up.data().uid + "/uploaded/" + up.data().title
              ).subscribe((snapshot) => {
                var tempImage: Image = up.data() as Image;
                tempImage.loaded = false;
                images.push(tempImage);
              });
            } else if (up.data().type.includes("video")) {
              this.getComments(
                "uploads/" + up.data().uid + "/uploaded/" + up.data().title
              ).subscribe((snapshot) => {
                var tempVideo: Video = up.data() as Video;
                videos.push(tempVideo);
              });
            }
          });
      });
    });
    return favouriteList;
  }

  post(
    uid: string,
    postText: string,
    unixTimestamp: number,
    profilePicture: string,
    displayName: string
  ) {
    const data = {
      post: postText,
      timestamp: unixTimestamp,
      type: "post",
      profilePicture: profilePicture,
      displayName: displayName,
      path: "uploads/" + uid + "/uploaded/" + unixTimestamp.toString(),
      likes: 0,
      views: 0,
      comments: 0,
      uid: uid,
      title: unixTimestamp,
    };
    return this.afs
      .doc("uploads/" + uid + "/uploaded/" + unixTimestamp.toString())
      .set(data);
  }

  getmyprofileTest() {
    const usersRef = this.afs.collectionGroup("uploaded", (ref) =>
      ref.where("category", "==", "photography")
    );
    usersRef.get().subscribe((x) => {
      x.forEach((doc) => {});
    });
  }

  exploreAll() {
    const usersRef = this.afs.collectionGroup("uploaded", (ref) =>
      ref.orderBy("timestamp", "desc").limit(30)
    );
    return usersRef.get();
  }

  exploreMore(lastItem) {
    return this.afs
      .collectionGroup("uploaded", (ref) =>
        ref.orderBy("timestamp", "desc").startAfter(lastItem).limit(30)
      )
      .get();
  }

  exploreCategory(category: string) {
    if (category === "post") {
      const usersRef = this.afs.collectionGroup("uploaded", (ref) =>
        ref.where("type", "==", category).orderBy("timestamp", "desc").limit(30)
      );
      return usersRef.get();
    } else {
      const usersRef = this.afs.collectionGroup("uploaded", (ref) =>
        ref
          .where("category", "==", category)
          .orderBy("timestamp", "desc")
          .limit(30)
      );
      return usersRef.get();
    }
  }

  exploreMoreCategory(lastItem, category: string) {
    if (category === "post") {
      return this.afs
        .collectionGroup("uploaded", (ref) =>
          ref
            .where("type", "==", category)
            .orderBy("timestamp", "desc")
            .startAfter(lastItem)
            .limit(30)
        )
        .get();
    } else {
      return this.afs
        .collectionGroup("uploaded", (ref) =>
          ref
            .where("category", "==", category)
            .orderBy("timestamp", "desc")
            .startAfter(lastItem)
            .limit(30)
        )
        .get();
    }
  }

  deleteContent(uid: string, title: string) {
    this.afs.doc("uploads/" + uid + "/uploaded/" + title).delete();
  }

  getMaxUploadSize() {
    return this.afs.doc("config/uploadConfig").get();
  }

  getCompMaxUploadSize(competitionPeriod: string, competitionId: string) {
    return this.afs
      .doc(
        "competitions/" + competitionPeriod + "/competitions/" + competitionId
      )
      .get();
  }

  getFeaturedContent() {
    return this.afs.doc("home-config/featuredContent").get();
  }

  addStream(
    uid: string,
    broadcastId: string,
    streamId: string,
    chatId: string,
    displayName: string,
    timestamp: number,
    title: string,
    description: string
  ) {
    const streamRef = this.afs.doc("streams/" + uid + "/stream/" + broadcastId);
    const data = {
      broadcastId: broadcastId,
      streamId: streamId,
      uid: uid,
      chatId: chatId,
      displayName: displayName,
      timestamp: timestamp,
      title: title,
      description: description,
    };
    streamRef.set(data);
  }

  getStreams() {
    const steamRef = this.afs.collectionGroup("stream", (ref) =>
      ref.orderBy("timestamp", "desc").limit(10)
    );
    return steamRef.get();
  }

  getArtistStreams(displayName: string) {
    const steamRef = this.afs.collectionGroup("stream", (ref) =>
      ref
        .where("displayName", "==", displayName)
        .orderBy("timestamp", "desc")
        .limit(10)
    );
    return steamRef.get();
  }

  getStreamChat(brodcastId: string) {
    const streamRef = this.afs.collectionGroup("stream", (ref) =>
      ref.where("broadcastId", "==", brodcastId)
    );
    return streamRef.get();
  }

  sendMessage(
    yourUid: string,
    theirUid: string,
    message: string,
    displayName: string
  ) {
    var timestamp = (new Date().getTime() / 1000) | 0;

    const noti = this.afs.doc(
      "notifications/" + theirUid + "/messages/" + timestamp
    );

    const notiData = {
      theirdisplayName: displayName,
      timestamp: timestamp,
    };

    noti.set(notiData);

    const messageRef = this.afs.doc(
      "messages/" + yourUid + "/message/" + theirUid
    );
    const othermessageRef = this.afs.doc(
      "messages/" + theirUid + "/message/" + yourUid
    );

    messageRef.get().subscribe((doc) => {
      if (doc.exists) {
        var messages = doc.data().message;
        messages.push(yourUid);
        messages.push(message);

        const data = {
          message: messages,
        };

        othermessageRef.update(data);
        messageRef.update(data);
      } else {
        const yourdata = {
          message: [yourUid, message],
          otheruid: theirUid,
        };
        const theirdata = {
          message: [yourUid, message],
          otheruid: yourUid,
        };

        othermessageRef.set(theirdata);
        messageRef.set(yourdata);
      }
    });
    return messageRef.get();
  }

  getMessage(youruid: string, theiruid: string) {
    const messageRef = this.afs.doc(
      "messages/" + youruid + "/message/" + theiruid
    );
    return messageRef.get();
  }

  getMessengers(uid: string) {
    const messRef = this.afs.collection("messages/" + uid + "/message");
    return messRef.get();
  }

  addView(uid: string, title: string) {
    const viewRef = this.afs.doc("uploads/" + uid + "/uploaded/" + title);

    viewRef.get().subscribe((x) => {
      var newViews = x.data().views + 1;
      viewRef.update({ views: newViews });
    });
  }

  addSchedule(
    uid: string,
    displayName: string,
    profilePicture: string,
    scheduledate: string,
    timestamp: number
  ) {
    const scheduleRef = this.afs.doc("scheduledstreams/" + uid);

    const data = {
      displayName: displayName,
      profilePicture: profilePicture,
      scheduledate: scheduledate,
      timestamp: timestamp,
    };

    return scheduleRef.set(data);
  }
  getSchedule() {
    var now = (new Date().getTime() / 1000) | 0;
    var ago = now - 172800;
    return this.afs
      .collection("scheduledstreams", (ref) =>
        ref.where("timestamp", ">", ago).orderBy("timestamp", "desc").limit(4)
      )
      .get();
  }

  getNotifications(uid: string) {
    const noti = this.afs.collection(
      "notifications/" + uid + "/notification",
      (ref) => ref.orderBy("timestamp", "desc")
    );
    return noti.get();
  }

  getNotificationsBar(uid: string) {
    const noti = this.afs.collection(
      "notifications/" + uid + "/notification",
      (ref) => ref.where("seen", "==", false).orderBy("timestamp", "desc")
    );
    return noti.snapshotChanges();
  }

  notificationRead(path: string) {
    const noti = this.afs.doc(path);
    return noti.update({ seen: true });
  }

  notificationDelete(path: string) {
    const noti = this.afs.doc(path);
    return noti.delete();
  }

  getMessages(uid: string) {
    const noti = this.afs.collection(
      "notifications/" + uid + "/messages",
      (ref) => ref.orderBy("timestamp", "desc")
    );
    return noti.get();
  }

  getMessagesBar(uid: string) {
    const noti = this.afs.collection(
      "notifications/" + uid + "/messages",
      (ref) => ref.orderBy("timestamp", "desc")
    );
    return noti.snapshotChanges();
  }

  checkEntriesVotes(uid: string, competitionId: string) {
    return this.afs
      .collectionGroup("votes", (ref) =>
        ref
          .where("voterUid", "==", uid)
          .where("competitionId", "==", competitionId)
      )
      .valueChanges();
  }

  submitCompetitionForm(data: any) {
    return this.afs
      .doc("CompetitionForms/" + data.uid + "/form/" + this.afs.createId())
      .set(data);
  }
}
