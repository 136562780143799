import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthguardGuard } from "./service/authguard.guard";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    redirectTo: "untapped-home",
    pathMatch: "full",
  },
  {
    path: "profilepicture",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./home/profilepicture/profilepicture.module").then(
        (m) => m.ProfilepicturePageModule
      ),
  },
  {
    path: "biography",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./home/biography/biography.module").then(
        (m) => m.BiographyPageModule
      ),
  },
  {
    path: "profilehome",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./profile/profilehome/profilehome.module").then(
        (m) => m.ProfilePageModule
      ),
  },
  {
    path: "upload",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./profile/upload/upload.module").then((m) => m.UploadPageModule),
  },
  {
    path: "dashboard-old",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./profile/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "settings",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./profile/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: "search",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./search/search.module").then((m) => m.SearchPageModule),
  },
  {
    path: "explore",
    loadChildren: () =>
      import("./explore/explore.module").then((m) => m.ExplorePageModule),
  },
  {
    path: "competitions",
    loadChildren: () =>
      import("./competitions/competitions.module").then(
        (m) => m.CompetitionsPageModule
      ),
  },
  {
    path: "competition-tabs",
    loadChildren: () =>
      import("./competition-tabs/competition-tabs.module").then(
        (m) => m.CompetitionTabsPageModule
      ),
  },
  {
    path: "image-vote",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./image-vote/image-vote.module").then(
        (m) => m.ImageVotePageModule
      ),
  },
  {
    path: "audio-vote",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./audio-vote/audio-vote.module").then(
        (m) => m.AudioVotePageModule
      ),
  },
  {
    path: "video-vote",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./video-vote/video-vote.module").then(
        (m) => m.VideoVotePageModule
      ),
  },
  {
    path: "submit-entry",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./submit-entry/submit-entry.module").then(
        (m) => m.SubmitEntryPageModule
      ),
  },
  {
    path: "untapped-home",
    loadChildren: () =>
      import("./untapped-home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "dashboard",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./dashboard/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "followers-list",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./dashboard/follower-list/follower-list.module").then(
        (m) => m.FollowerListPageModule
      ),
  },
  {
    path: "comments",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./comments/comments.module").then((m) => m.CommentsPageModule),
  },
  {
    path: "payment",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./payment/payment/payment.module").then(
        (m) => m.PaymentPageModule
      ),
  },
  {
    path: "processing",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./payment/processing/processing.module").then(
        (m) => m.ProcessingPageModule
      ),
  },
  {
    path: "legal",

    loadChildren: () =>
      import("./legal/legal/legal.module").then((m) => m.LegalPageModule),
  },
  {
    path: "competition-host",
    loadChildren: () =>
      import(
        "./dashboard/competition-host/competition-host/competition-host.module"
      ).then((m) => m.CompetitionHostPageModule),
  },
  {
    path: 'image-preview',
    loadChildren: () => import('./image-preview/image-preview.module').then( m => m.ImagePreviewPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
