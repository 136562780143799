import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../../service/auth.service";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { AudioService } from "../../service/audio.service";

export interface Track {
  title: string;
  url: string;
  displayName: string;
  uid: string;
  profilePicture: string;
}

@Component({
  selector: "app-viewupload",
  templateUrl: "./viewupload.component.html",
  styleUrls: ["./viewupload.component.scss"],
})
export class ViewuploadComponent implements OnInit {
  @Input() profilePicture: string;
  @Input() theiruid: string;
  @Input() url: string;
  @Input() likes: number;
  @Input() views: number;
  @Input() description: string;
  @Input() displayName: string;
  @Input() path: string;
  @Input() title: string;
  @Input() type: string;
  @Input() post: string;
  youruid: string;
  yourdisplayName: string;
  yourProfilePicture: string;
  comment: string = "";
  commentList: Array<any> = [];
  track: Track = null;
  enlarged = false;
  favouriteString: string = "Favourite";
  likeString: string = "Like";

  constructor(
    public auth: AuthService,
    private router: Router,
    public aud: AudioService
  ) {}

  ngOnInit() {
    let pp = document.getElementById("pp");
    pp.setAttribute("src", this.profilePicture);

    this.auth.user$.subscribe((x) => {
      this.youruid = x.uid;
      this.yourdisplayName = x.displayName;
      this.yourProfilePicture = x.profilePicture;
      if (this.type.includes("audio")) {
        this.playAudio();
      }
      if (this.youruid == this.theiruid) {
        let fb = document.getElementById("f");
        fb.style.display = "none";
      }

      if (this.youruid != this.theiruid) {
        this.auth.addView(this.theiruid, this.title);
      }

      this.auth
        .checkRefExists(
          "following/" + this.youruid + "/followed/" + this.theiruid
        )
        .subscribe((z) => {
          if (z.exists == true) {
            let f = document.getElementById("f");
            let fing = document.getElementById("fing");

            f.style.display = "none";
            fing.style.display = "inline-block";
          }
        });

      this.auth
        .checkRefExists(
          "favourites/" +
            this.youruid +
            "/favourite/" +
            this.theiruid +
            this.title
        )
        .subscribe((v) => {
          if (v.exists == true) {
            this.favouriteString = "Favourited";
          }
        });

      this.auth
        .checkRefExists(
          "likes/" + this.youruid + "/" + this.theiruid + "/" + this.title
        )
        .subscribe((x) => {
          if (x.exists == true) {
            this.likeString = "Liked";
          }
        });

      if (this.type == "post") {
        let fav = document.getElementById("favourite");
        fav.style.display = "none";
        let desc = document.getElementById("description");
        desc.style.display = "none";
      }

      this.commentList = this.auth.getCommentList(this.path);
    });
  }

  like() {
    if (this.likeString === "Like") {
      //this.auth.likeUpload(this.path,this.youruid,this.likes,this.title,this.theiruid, this.yourdisplayName, this.type);
      this.likeString = "Liked";
      this.likes += 1;
    }
  }

  uploadComment() {
    this.auth
      .addComment(
        this.path,
        this.youruid,
        this.comment,
        new Date().toUTCString(),
        this.yourdisplayName,
        this.yourProfilePicture,
        this.theiruid,
        this.title,
        this.type
      )
      .then(() => {
        this.comment = "";
        this.commentList = this.auth.getCommentList(this.path);
      });
  }

  playAudio() {
    this.track = {
      displayName: this.displayName,
      profilePicture: this.profilePicture,
      title: this.title,
      uid: this.theiruid,
      url: this.url,
    };

    this.aud.start(this.track);
  }

  toProfilePage() {
    this.router.navigate(["profilehome/" + this.theiruid]);
  }

  follow() {
    this.auth
      .follow(
        this.theiruid,
        this.youruid,
        this.displayName,
        this.yourdisplayName
      )
      .then(() => {
        let f = document.getElementById("follow");
        let fing = document.getElementById("following");

        f.style.display = "none";
        fing.style.display = "inline-block";
      });
  }

  addToFavourites() {
    if (this.favouriteString === "Favourite") {
      this.auth
        .addToFavourites(this.youruid, this.path, this.theiruid, this.title)
        .then(() => {
          this.favouriteString = "Favourited";
        });
    }
  }

  enlarge() {
    let d = document.getElementById("d1");
    let img = document.getElementById("img");
    if (d.style.display == "none") {
      d.style.display = "inline-block";
      img.style.height = "250px";
      let d3 = document.getElementById("d3");
      d3.style.display = "initial";
    } else {
      d.style.display = "none";
      let d3 = document.getElementById("d3");
      d3.style.display = "none";
      img.style.height = "400px";
    }
  }
}
