import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { CommonModule } from "@angular/common";
import { ViewuploadComponent } from "./profile/viewupload/viewupload.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AudioplayerComponent } from "./audioplayer/audioplayer.component";
import { ClipboardModule } from "ngx-clipboard";

import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxIonicImageViewerModule } from "ngx-ionic-image-viewer";
import { PdfViewerModule } from "ng2-pdf-viewer";

const config = {
  apiKey: "AIzaSyDdTKJWBPlMaCrIbv7xrmbFOWip8UGfxaQ",
  authDomain: "untapped-culture.firebaseapp.com",
  databaseURL: "https://untapped-culture.firebaseio.com",
  projectId: "untapped-culture",
  storageBucket: "untapped-culture.appspot.com",
  messagingSenderId: "22811957734",
  appId: "1:22811957734:web:56fa2649ade2b2c9cd5e27",
  measurementId: "G-8TKXB6JWBD",
};

@NgModule({
  declarations: [AppComponent, ViewuploadComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireAnalyticsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ClipboardModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    InfiniteScrollModule,
    NgxIonicImageViewerModule,
    ReactiveFormsModule,
    PdfViewerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
