import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, timer } from "rxjs";
import { AuthService } from "./auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { first, tap, map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthguardGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.user$.pipe(
      map((user) => {
        if (!user) {
          alert(
            "You must be logged in to visit this page. If you have not already, please try sign up before you log in."
          );
          this.auth.logOut();
          this.router.navigate(["untapped-home"]);
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
