import { Injectable, ViewChild } from "@angular/core";
import { Howl } from "howler";
import { IonRange } from "@ionic/angular";

export interface Track {
  title: string;
  url: string;
  displayName: string;
  uid: string;
  profilePicture: string;
}

@Injectable({
  providedIn: "root",
})
export class AudioService {
  playlist: Track[] = [];

  activeTrack: Track = null;
  player: Howl = null;
  isPlaying = false;
  progress = 0;
  volumeVal = 50;
  volumeIcon = "volume-medium-outline";
  duration = ":";
  time = "0:00";
  rangeVal = 0;
  volumeRange = 0;

  constructor() {}

  start(track: Track) {
    if (this.player) {
      this.player.stop();
    }
    this.player = new Howl({
      src: [track.url],
      volume: this.volumeVal / 100,
      html5: true,
      onplay: () => {
        this.isPlaying = true;
        this.activeTrack = track;
        this.updateProgress();
        this.duration = this.getDuration(this.player._duration);
      },
      onend: () => {},
    });
    this.player.play();
  }

  stop() {
    this.isPlaying = false;
    this.activeTrack = null;
    this.player.stop();
  }

  togglePlayer(pause) {
    this.isPlaying = !pause;
    if (pause) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  prev() {
    let index = this.playlist.indexOf(this.activeTrack);
    if (index > 0) {
      this.start(this.playlist[index - 1]);
    } else {
      this.start(this.playlist[this.playlist.length - 1]);
    }
  }

  next() {
    let index = this.playlist.indexOf(this.activeTrack);
    if (index != this.playlist.length - 1) {
      this.start(this.playlist[index + 1]);
    } else {
      this.start(this.playlist[0]);
    }
  }

  seek() {
    let newValue = +this.rangeVal;
    let duration = this.player.duration();
    this.player.seek((duration * newValue) / 100);
  }

  updateProgress() {
    let seek = this.player.seek();
    this.progress = (seek / this.player.duration()) * 100 || 0;
    this.time = this.getDuration(seek);
    setTimeout(() => {
      this.updateProgress();
    }, 1000);
  }

  volumeControl() {
    this.volumeVal = +this.volumeRange;
    this.player.volume(this.volumeVal / 100);
    this.getVolumeIcon(this.volumeVal);
  }

  getDuration(length) {
    let minutes = Math.floor(length / 60);
    let seconds = Math.round(length % 60)
      .toString()
      .padStart(2, "0");
    return minutes + ":" + seconds;
  }

  getVolumeIcon(volVal) {
    if (volVal === 0) {
      this.volumeIcon = "volume-mute-outline";
    } else if (volVal > 0 && volVal < 25) {
      this.volumeIcon = "volume-off-outline";
    } else if (volVal >= 25 && volVal < 50) {
      this.volumeIcon = "volume-low-outline";
    } else if (volVal >= 50 && volVal < 75) {
      this.volumeIcon = "volume-medium-outline";
    } else {
      this.volumeIcon = "volume-high-outline";
    }
  }

  addToPlaylist(track: Track) {
    this.playlist.push(track);
  }
}
